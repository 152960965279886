import React from "react";
import { GoGoal } from "react-icons/go";
import { TbReportAnalytics } from "react-icons/tb";
import { MdOutlineFolderShared } from "react-icons/md";
import { FaRegFileImage } from "react-icons/fa";
import { AiOutlineContainer, AiOutlineFilePdf } from "react-icons/ai";

export const TypeIcon = (props: { itemType: any | unknown }) => {
  switch (props.itemType) {
    case "SUBJECT":
      return <AiOutlineContainer size={24} />;
    case "GOAL":
      return <GoGoal size={24} />;
    case "MEASUREMENT":
      return <TbReportAnalytics size={24} />;
    case "FOLDER":
      return <MdOutlineFolderShared size={24} />;
    case "application/pdf":
      return <AiOutlineFilePdf size={24} />;
    case "image/jpeg":
      return <FaRegFileImage size={24} />;
    default:
      return null;
  }
};
