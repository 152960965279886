"use client"; // Error components must be Client Components

import React, { useEffect } from "react";

import { FallbackErrorComponent } from "@guberno/ui";

export default function Error({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <div>
      <FallbackErrorComponent error={error} resetErrorBoundary={reset} />
    </div>
  );
}
