"use client";

import React from "react";
import { WcTypography, WcStack, WcButton } from "@bpipartners/webui";
interface FallbackErrorComponentProps {
  error: any;
  resetErrorBoundary: any;
}

export const FallbackErrorComponent = ({
  error,
  resetErrorBoundary,
}: FallbackErrorComponentProps) => (
  <WcStack
    id="FallbackErrorComponent"
    spacing={2}
    alignItems="center"
    width="300"
    padding={2}
  >
    <WcStack spacing={2} alignItems="center" width="100%" padding={2}>
      <WcTypography>Er is iets mis gegaan:</WcTypography>
      <WcTypography>{error.message}</WcTypography>

      {/* <WcButton variant="contained" onClick={resetErrorBoundary}>
        Try again
      </WcButton> */}
    </WcStack>
  </WcStack>
);

export default FallbackErrorComponent;
