import React from "react";
import { TypeIcon } from "./TypeIcon.View";
import styles from "./CustomDragPreview.module.css";

export const CustomDragPreview = (props: any) => {
  const item = props.monitorProps.item;

  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <TypeIcon
          //droppable={item.droppable || false}
          //@ts-ignore
          itemType={item?.itemType}
        />
      </div>
      <div className={styles.label}>{item.text}</div>
    </div>
  );
};
