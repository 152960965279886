import {
  WcButton,
  WcDialog,
  WcIconButton,
  WcStack,
  WcTypography,
} from "@bpipartners/webui";
import { useWindowDimensions } from "@guberno/utils/hooks";
import React from "react";
import {
  Viewer,
  Worker,
  LocalizationMap,
  SpecialZoomLevel,
} from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
  highlightPlugin,
  HighlightArea,
  RenderHighlightContentProps,
  RenderHighlightsProps,
  MessageIcon,
  RenderHighlightTargetProps,
} from "@react-pdf-viewer/highlight";
import { MdOutlineStickyNote2 } from "react-icons/md";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import type {
  ToolbarProps,
  ToolbarSlot,
  TransformToolbarSlot,
} from "@react-pdf-viewer/toolbar";
import { AppContext } from "@guberno/utils/context";
import { useTranslations } from "next-intl";
import { MdDelete, MdEdit } from "react-icons/md";
import { useTheme } from "@guberno/utils/theme";

interface ReactPdfViewerProps {
  documentID: string;
  annotationNotes: any;
  onNoteAdded: (note: any) => void;
}

interface Note {
  id: number;
  content: string;
  highlightAreas: HighlightArea[];
  quote: string;
  createdBy: string;
}

const nlLocale = {
  attachment: {
    clickToDownload: "Klik om te downloaden",
    noAttachment: "er is geen bijlage",
  },
  bookmark: {
    noBookmark: "Er is geen bladwijzer",
  },
  core: {
    askingPassword: {
      requirePasswordToOpen:
        "Om dit document te openen is een wachtwoord vereist",
      submit: "Indienen",
    },
    wrongPassword: {
      submit: "Indienen",
      tryAgain: "Het wachtwoord is verkeerd. ",
    },
    pageLabel: "Bladzijde {{pageIndex}}",
  },
  defaultLayout: {
    attachment: "Bijlage",
    bookmark: "Bladwijzer",
    thumbnail: "Miniatuur",
  },
  download: {
    download: "Downloaden",
  },
  drop: {
    dragDropFile: "Sleep hier een PDF-document en zet het daar neer",
  },
  fullScreen: {
    enterFullScreen: "Volledig scherm",
    exitFullScreen: "Verlaat volledig scherm",
  },
  localeSwitcher: {
    switchLocale: "Wijzig de landinstelling",
  },
  open: {
    openFile: "Open bestand",
  },
  pageNavigation: {
    enterPageNumber: "Voer een paginanummer in",
    goToFirstPage: "Eerste pagina",
    goToLastPage: "Laatste pagina",
    goToNextPage: "Volgende bladzijde",
    goToPreviousPage: "Vorige bladzijde",
  },
  print: {
    cancel: "Annuleren",
    preparingDocument: "Document voorbereiden...",
    print: "Afdrukken",
  },
  properties: {
    author: "Auteur",
    close: "Dichtbij",
    creationDate: "Aanmaakdatum",
    creator: "Schepper",
    fileName: "Bestandsnaam",
    fileSize: "Bestandsgrootte",
    keywords: "Trefwoorden",
    modificationDate: "Wijzigingsdatum",
    pageCount: "Aantal pagina's",
    pdfProducer: "PDF-producent",
    pdfVersion: "PDF-versie",
    showProperties: "Toon eigenschappen",
    subject: "Onderwerp",
    title: "Titel",
  },
  rotate: {
    rotateBackward: "Draai tegen de klok in",
    rotateForward: "Met de klok mee draaien",
  },
  scrollMode: {
    horizontalScrolling: "Horizontaal scrollen",
    verticalScrolling: "Verticale scrolling",
    wrappedScrolling: "Verpakt scrollen",
  },
  search: {
    close: "Dichtbij",
    enterToSearch: "Voer in om te zoeken",
    matchCase: "Match geval",
    nextMatch: "Volgende wedstrijd",
    previousMatch: "Vorige wedstrijd",
    search: "Zoekopdracht",
    wholeWords: "Hele woorden",
  },
  selectionMode: {
    handTool: "Handgereedschap",
    textSelectionTool: "Hulpmiddel voor tekstselectie",
  },
  theme: {
    switchDarkTheme: "Schakel over naar het donkere thema",
    switchLightTheme: "Schakel over naar het lichtthema",
  },
  thumbnail: {
    thumbnailLabel: "Miniatuur van pagina {{pageIndex}}",
  },
  toolbar: {
    moreActions: "Meer acties",
  },
  zoom: {
    actualSize: "Daadwerkelijke grootte",
    pageFit: "Pagina passend",
    pageWidth: "Paginabreedte",
    zoomDocument: "Zoomdocument",
    zoomIn: "In zoomen",
    zoomOut: "Uitzoomen",
  },
};

export const ReactPdfViewer = ({
  documentID,
  annotationNotes,
  onNoteAdded,
}: ReactPdfViewerProps) => {
  const theme = useTheme();
  const [message, setMessage] = React.useState("");
  const { localAppState } = React.useContext(AppContext);
  const [notes, setNotes] = React.useState<Note[]>([]);
  const [currentNote, setCurrentNote] = React.useState<Note | null>(null);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  React.useEffect(() => {
    if (
      annotationNotes &&
      annotationNotes.note &&
      annotationNotes.note.length > 0
    ) {
      const newArray: Note[] = [];

      annotationNotes.note.forEach((item: any) => {
        newArray.push({
          id: item.id,
          content: item.content,
          highlightAreas: item.highlightAreas,
          quote: item.quote,
          createdBy: annotationNotes.createdByName,
        });
      });

      setNotes(newArray);
    }
  }, [annotationNotes]);

  let noteId = notes.length;
  const t = useTranslations();

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    EnterFullScreen: () => <></>,
    Print: () => <></>,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    PrintMenuItem: () => <></>,
    SwitchTheme: () => <></>,

    SwitchThemeMenuItem: () => <></>,
  });

  const renderToolbar = (
    Toolbar: (props: ToolbarProps) => React.ReactElement
  ) => (
    <Toolbar>
      {defaultLayoutPluginInstance.toolbarPluginInstance.renderDefaultToolbar(
        transform
      )}
    </Toolbar>
  );

  const handleDelete = (id: number) => {
    notes.splice(id, 1);
    const newNotes = notes;
    onNoteAdded(newNotes);
  };

  const handleEdit = (note: Note) => {
    setCurrentNote(note);
    setOpenEditDialog(true);
  };

  // config for highlight plugin
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) =>
      //@ts-ignore
      defaultTabs.concat({
        //body of the tab
        content: (
          <WcStack
            style={{
              borderRight: "1px solid rgba(0, 0, 0, 0.3)",
              width: "100%",
              overflow: "auto",
            }}
          >
            {notes.length === 0 && (
              <WcStack style={{ textAlign: "center" }}>
                {t("labels.noContent")}
              </WcStack>
            )}
            {notes.map((note, index) => (
              <WcStack
                direction={"row"}
                key={note.id}
                justifyContent={"space-between"}
                style={{
                  borderBottom: "1px solid rgba(0, 0, 0, .3)",
                  cursor: "pointer",
                  padding: "8px",
                }}
                // Jump to the associated highlight area
                onClick={() => jumpToHighlightArea(note.highlightAreas[0])}
              >
                <WcStack>
                  <blockquote
                    style={{
                      borderLeft: "2px solid rgba(0, 0, 0, 0.2)",
                      fontSize: ".75rem",
                      lineHeight: 1.5,
                      margin: "0 0 8px 0",
                      paddingLeft: "8px",
                      textAlign: "justify",
                    }}
                  >
                    {note.quote}
                  </blockquote>
                  <WcTypography>{note.content} </WcTypography>
                  <WcTypography>{note.createdBy} </WcTypography>
                </WcStack>
                <WcStack direction={"row"}>
                  <WcIconButton
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                    onClick={() => handleDelete(index)}
                    title={t("labels.delete")}
                  >
                    <MdDelete color={theme.appColors.primary} size={16} />
                  </WcIconButton>
                  <WcIconButton
                    onClick={() => handleEdit(note)}
                    disableRipple
                    title={t("labels.edit")}
                  >
                    <MdEdit color={theme.appColors.primary} size={16} />
                  </WcIconButton>
                </WcStack>
              </WcStack>
            ))}
          </WcStack>
        ),

        icon: <MessageIcon />,
        title: t("labels.note"),
      }),
  });

  const { activateTab } = defaultLayoutPluginInstance;
  const noteEles: Map<number, HTMLElement> = new Map();
  const [documentURl] = React.useState(
    `/files/fileDownloadUrl?itemID=${documentID}`
  );
  const { height } = useWindowDimensions();
  // render button that appears when you click on text
  const renderHighlightTarget = (props: RenderHighlightTargetProps) => (
    <WcStack
      style={{
        display: "flex",
        position: "absolute",
        left: `${Math.abs(props.selectionRegion.left)}%`,
        top: `${Math.abs(
          props.selectionRegion.top + props.selectionRegion.height
        )}%`,
        transform: "translate(0, 8px)",
        zIndex: 1,
      }}
    >
      <WcButton onClick={props.toggle} variant="contained">
        <MdOutlineStickyNote2 size={24} title={t("labels.addNote")} />
      </WcButton>
    </WcStack>
  );
  // method to add note and also  that holds the textblock
  const renderHighlightContent = (props: RenderHighlightContentProps) => {
    const addNote = () => {
      if (message !== "") {
        const note: Note = {
          id: ++noteId,
          content: message,
          highlightAreas: props.highlightAreas,
          quote: props.selectedText,
          createdBy: localAppState.userData.userID,
        };
        setNotes(notes.concat([note]));
        onNoteAdded(notes.concat([note]));
        props.cancel();

        setMessage("");
      }
    };

    return (
      <div
        style={{
          background: "#fff",
          border: "1px solid rgba(0, 0, 0, .3)",
          borderRadius: "2px",
          padding: "8px",
          position: "absolute",
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          zIndex: 1,
        }}
      >
        <div>
          <textarea
            rows={3}
            style={{
              width: 400,
              border: "1px solid rgba(0, 0, 0, .3)",
            }}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "8px",
          }}
        >
          <div style={{ marginRight: "8px" }}>
            <WcButton variant="contained" onClick={addNote}>
              {t("labels.save")}
            </WcButton>
          </div>
          <WcButton onClick={props.cancel}>{t("labels.cancel")}</WcButton>
        </div>
      </div>
    );
  };
  // jumpt to note method
  const jumpToNote = (note: Note) => {
    // Open the tab
    activateTab(3);
    defaultLayoutPluginInstance.toggleTab(3);
    if (noteEles.has(note.id)) {
      noteEles.get(note.id).scrollIntoView();
    }
  };

  // method that highlight the anchors
  const renderHighlights = (props: RenderHighlightsProps) => (
    <WcStack>
      {notes.map((note) => (
        <React.Fragment key={note.id}>
          {note.highlightAreas
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <WcStack
                key={idx}
                style={Object.assign(
                  {},
                  {
                    background: "yellow",
                    opacity: 0.4,
                  },
                  props.getCssProperties(area, props.rotation)
                )}
                onClick={() => jumpToNote(note)}
                ref={(ref): void => {
                  noteEles.set(note.id, ref as HTMLElement);
                }}
              />
            ))}
        </React.Fragment>
      ))}
    </WcStack>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlightContent,
    renderHighlights,
  });
  const handleCancel = () => {
    setOpenEditDialog(false);
    setMessage("");
  };

  const handleConfirm = () => {
    setOpenEditDialog(false);
    const findItemIndex = notes.findIndex((note) => note.id === currentNote.id);
    notes[findItemIndex].content = message;
    onNoteAdded(notes);
    setMessage("");
  };
  const { jumpToHighlightArea } = highlightPluginInstance;
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
      <WcStack id="ReactPdfViewer">
        <WcStack
          id="pdfStack"
          height={height - 95}
          width={"100%"}
          overflow={"auto"}
        >
          <Viewer
            localization={nlLocale as unknown as LocalizationMap}
            fileUrl={documentURl}
            plugins={[defaultLayoutPluginInstance, highlightPluginInstance]}
            defaultScale={SpecialZoomLevel.PageWidth}
          />
        </WcStack>
        {currentNote && openEditDialog && (
          <WcDialog
            handleCancel={handleCancel}
            handleClosePressed={handleConfirm}
            heading={t("common.editNote")}
            leftButtonHeading={t("labels.cancel")}
            rightButtonHeading={t("common.confirm")}
            open={openEditDialog}
          >
            <textarea
              rows={3}
              style={{
                width: 400,
                border: "1px solid rgba(0, 0, 0, .3)",
              }}
              onChange={(e) => setMessage(e.target.value)}
            >
              {currentNote.content}
            </textarea>
          </WcDialog>
        )}
      </WcStack>
    </Worker>
  );
};

export default ReactPdfViewer;
