import { WcStack } from "@bpipartners/webui";
import React from "react";
import { BreadCrumbs, BreadCrumbsProps, HeaderComponent } from "@guberno/ui";
import { useTheme } from "@guberno/utils/theme";

interface PageHeaderProps {
  children?: React.ReactNode;
  pageTitle: string;
  pageDescription: string;
  height?: string | number;
  breadcrumbsProps?: BreadCrumbsProps;
}

export const PageHeader = ({
  children,
  pageDescription,
  pageTitle,
  height = undefined,
  breadcrumbsProps,
}: PageHeaderProps) => {
  const theme = useTheme();

  return (
    <WcStack height={theme.dimensions.totalTopbarHeight} id="PageHeader">
      <WcStack
        id="breadCrumbStack"
        minHeight={theme.dimensions.breadCrumbHeight}
        marginBottom={16}
        bgcolor={theme.appColors.background}
        justifyContent={"center"}
      >
        {breadcrumbsProps && <BreadCrumbs {...breadcrumbsProps} />}
      </WcStack>

      <WcStack padding={12}>
        <HeaderComponent
          pageTitle={pageTitle}
          pageDescription={pageDescription}
        >
          {children}
        </HeaderComponent>
      </WcStack>
    </WcStack>
  );
};

export default PageHeader;
