import { WcBreadcrumb, WcStack, WcTypography } from "@bpipartners/webui";
import { useTheme } from "@guberno/utils/theme";
import React from "react";
import { FaAngleRight } from "react-icons/fa";

export interface BreadCrumbsProps {
  data: any[];
  currentItem: string;
  onClick: (item: any) => void;
}

export const BreadCrumbs = ({
  data,
  onClick,
  currentItem,
}: BreadCrumbsProps) => {
  const theme = useTheme();

  return (
    <WcStack id="BreadCrumbs">
      <WcBreadcrumb
        separator={
          <WcStack paddingBottom={1}>
            <FaAngleRight fontSize="small" />
          </WcStack>
        }
      >
        {data.map((item, index) => (
          <WcTypography
            key={index}
            style={{
              cursor: "pointer",
              color:
                currentItem.toString() === item
                  ? theme.appColors.primary
                  : "inherit",
            }}
            onClick={() => {
              onClick(item);
            }}
          >
            {item}
          </WcTypography>
        ))}
      </WcBreadcrumb>
    </WcStack>
  );
};

export default BreadCrumbs;
