"use client";
import React, { useState } from "react";
import {
  WcList,
  WcListItemButton,
  WcListItemText,
  WcPaper,
  WcStack,
} from "@bpipartners/webui";
import { useTranslations } from "next-intl";
import { useTheme } from "@guberno/utils/theme";

interface SideMenuListProps {
  listItems: any[];
  id?: string;
  onListItemClicked: (menuItemID: string) => void;
}

export const SideMenuList = ({
  id,
  listItems,
  onListItemClicked,
}: SideMenuListProps) => {
  const t = useTranslations();
  const theme = useTheme();

  const [selectedMenuItemID, setSelectedMenuItemID] = useState<string>();

  const handleClick = (menuItemID: string) => {
    setSelectedMenuItemID(menuItemID);
    onListItemClicked(menuItemID);
  };

  const getMenuBackgroundColor = (menuID: any) => {
    if (menuID === selectedMenuItemID) {
      return theme.appPalette.neutral[98];
    } else {
      return "undefined";
    }
  };

  return (
    <WcStack id="SideMenuList" paddingRight={2} minWidth={300} width={300}>
      <WcPaper
        sx={{
          height: "100%",
          backgroundColor: "white",
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          border: "hidden",
          borderRight: `1px solid lightgrey`,
        }}
      >
        <WcList aria-labelledby="nested-list-subheader">
          {listItems &&
            listItems.length > 0 &&
            listItems.map((item) => (
              <WcListItemButton
                key={item.id + item.name + "subMenu"}
                divider
                onClick={() => handleClick(item.id)}
                sx={{
                  backgroundColor: getMenuBackgroundColor(item.id),
                  "&:hover": {
                    backgroundColor: theme.appPalette.neutral[99],
                  },
                  borderBottom: "1 solid grey",
                }}
              >
                <WcListItemText
                  sx={{
                    color: "inherit",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    textDecoration: "none",
                  }}
                  primary={t(`value.${item.name}`)}
                />
              </WcListItemButton>
            ))}
        </WcList>
      </WcPaper>
    </WcStack>
  );
};
